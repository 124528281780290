exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsdraft-tsx": () => import("./../../../src/pages/newsdraft.tsx" /* webpackChunkName: "component---src-pages-newsdraft-tsx" */),
  "component---src-pages-newslist-tsx": () => import("./../../../src/pages/newslist.tsx" /* webpackChunkName: "component---src-pages-newslist-tsx" */),
  "component---src-pages-tbd-tsx": () => import("./../../../src/pages/tbd.tsx" /* webpackChunkName: "component---src-pages-tbd-tsx" */),
  "component---src-pages-winelist-tsx": () => import("./../../../src/pages/winelist.tsx" /* webpackChunkName: "component---src-pages-winelist-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/News.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

